import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import MoneyOrderInfo from "../components/App/MoneyOrderInfo"
import { token } from "../utils/api-values"
import { getId } from "../utils/inv-id"

import repository from "../repository/api_repository"
const BuyProPacket = props => {
  const packetAmount = props.amount
  const packetName = props.packetName
  const onSuccess = props.onSuccess
  const onFailed = props.onFailed

  const creditCardOption = "credit-card"
  const moneyOrderOption = "bank-transfer"

  const [paymentMethod, setPaymentMethod] = useState("")

  const [cardNumber, setCardNumber] = useState("")
  const [expiryDate, setExpiryDate] = useState("")
  const [name, setName] = useState("")
  const [cvv, setCvv] = useState("")

  async function handleSubmit(event) {
    event.preventDefault()
    const invId = parseInt(localStorage.getItem("invId"))

    if (isNaN(invId)) {
      return
    }

    let customerId = null

    const idResponse = await repository.invIdToCustomerId(invId)

    const idJson = await idResponse.json()
    console.log(idJson)

    customerId = idJson.returnid

    if (customerId == null) {
      onFailed("Id dönüşümü yapılamadı. Lütfen daha sonra tekrar deneyiniz.")
      return
    }

    if (paymentMethod == creditCardOption) {
      const cardNo = event.target.cardNumber.value
      const expiryDate = event.target.expiryDate.value
      const name = event.target.name.value
      const cvv = event.target.cvv.value
      const splittedDate = expiryDate.split("/")
      const month = splittedDate[0]
      const year = splittedDate[1]

      const payUrl = repository.getProPacketCreditCardPaymentURL(
        cardNo,
        name,
        cvv,
        month,
        year,
        customerId,
        packetAmount,
        packetName
      )
      console.log(payUrl)
      window.open(payUrl)
    } else if (paymentMethod == moneyOrderOption) {
      var response = await repository.buyProPacketMoneyOrder(
        customerId,
        packetName
      )

      const json = await response.json()
      console.log(json)
      if (json.status === 404) {
        onFailed(json.message)
      } else if (json.status === 200) {
        onSuccess()
      }
    }
  }
  function handleChange(event) {
    setPaymentMethod(event.target.value)
  }

  const PaymentMethod = () => {
    return (
      <div className="form-group">
        <label htmlFor="payment-method">Ödeme Yöntemi:</label>
        <select
          className="form-control"
          id="payment-method"
          value={paymentMethod}
          onChange={handleChange}
        >
          <option value="">Seçiniz</option>
          <option value={creditCardOption}>Kredi Kartı</option>
          <option value={moneyOrderOption}>Havale</option>
        </select>
      </div>
    )
  }

  const MoneyOrder = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <MoneyOrderInfo />
          <Footer />
        </div>
      </form>
    )
  }

  const paymentMethodSpecificComponent = () => {
    if (paymentMethod == creditCardOption) {
      return CreditCardForm()
    } else if (paymentMethod == moneyOrderOption) {
      return MoneyOrder()
    } else {
      return (
        <div className="container">
          <h5>Ödeme yöntemi seçiniz</h5>
        </div>
      )
    }
  }
  const CreditCardForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="cardNumber">Kart Numarası:</label>
          <input
            type="text"
            className="form-control"
            id="cardNumber"
            value={cardNumber}
            onChange={event => setCardNumber(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cardNumber">İsim Soyisim:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="expiryDate">Son Kullanma Tarihi:</label>
          <input
            type="text"
            className="form-control"
            id="expiryDate"
            value={expiryDate}
            onChange={event => setExpiryDate(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cvv">CVV:</label>
          <input
            type="text"
            className="form-control"
            id="cvv"
            value={cvv}
            onChange={event => setCvv(event.target.value)}
          />
        </div>
        <Footer />
      </form>
    )
  }

  const Footer = () => {
    return (
      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">
          İptal
        </Button>
        <Button type="submit"> Onayla</Button>
      </Modal.Footer>
    )
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pro paket al
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {PaymentMethod()}
        {paymentMethodSpecificComponent()}
      </Modal.Body>
    </Modal>
  )
}

export default BuyProPacket
