import React from "react"

const MoneyOrderInfo = () => {
  return (
    <div className="container">
      {/* <small>
        Havale işleminizin hızlıca tamamlanabilmesi için lütfen aşağıdaki
        açıklama kısmını kopyalayıp yapacağınız havalenin açıklamasına
        yapıştırınız.
      </small> */}
      <p>Aşağıdaki Hesaba İşlem Tutarınızı Yatırabilirsiniz:</p>
      <small>Alışverişlio Elektronik Ticaret Yatırım Anonim Şirketi</small>
      <br />
      <small>Vakıf Katılım Bankası</small>
      <br />
      <h5>TR46 0021 0000 0007 2984 4000 07</h5>
    </div>
  )
}

export default MoneyOrderInfo
